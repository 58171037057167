import React, { useState, Fragment, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper';
import * as nearAPI from 'near-api-js';

import CardModalVIPLS from '../CardModalVIPLS';

import 'swiper/scss';
import "swiper/css/effect-fade";

import TMMPimg1 from '../../../assets/images/GEMZ_DECAL_CHONACAS_TIEN_FINAL.png'
import logo from '../../../assets/images/WL/logo.png'
import { ReactComponent as NearType } from '../../../assets/images/icon/near_type.svg'

import { useSelector } from 'react-redux';

const LiveAuctionVIPLS = () => {

    const nearWallet = useSelector(state => state.near.wallet);
    const nftContract = useSelector(state => state.near.nftContract);
    const [modalShow, setModalShow] = useState(false);
    const [slideTitle, setSlideTitle] = useState('GemZ: NFT NYC 2022');
    const [qtySold, setQtySold] = useState('-');
    const [tokens, setTokens] = useState([]);
    const [mintError, setMintError] = useState(false);
    const [isMinting, setIsMinting] = useState(false);
    const { utils } = nearAPI;

    useEffect(() => {
        if (nftContract) {
            nftContract.get_user_minted_quantity().then((qty) => setQtySold(qty));
            if (nearWallet?.isSignedIn()) {
                nftContract.nft_tokens_for_owner({account_id: nearWallet.getAccountId()}).then((t) => { setTokens(t); });
            }
        }
    }, [nftContract, nearWallet]);

    const handlePurchase = async () => {
        setMintError(false);
        setIsMinting(true);
        const result = await nftContract.nft_mint(
            {},
            "75000000000000",
            utils.format.parseNearAmount("0") 
        ).catch(err => {
            setMintError(true);
            setIsMinting(false);
        });
        setIsMinting(false);
        window.location.reload();
    }

    return (
        <Fragment>
            <section className="tf-section live-auctions">
                { tokens && tokens.length > 0 
                  && tokens.map((nft, ind) => {
                      return <div key={'nft'+ind} className="themesflat-container">
                        <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                <div className="swiper-container show-shadow carousel auctions">
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                            <div className="slider-item">
                                                <div className="sc-card-product">
                                                    <h3 style={{textAlign: 'center', marginBottom: 20}}>Your NFT</h3>
                                                    <div className="card-media">
                                                        
                                                            <img src={nft.metadata.media} alt={nft.metadata.title} />
                                                    </div>
                                                    <div className="card-title" style={{flexDirection: 'column'}}>
                                                        <h5><a>{nft.metadata.title}</a></h5>
                                                        <p style={{fontSize: 14, lineHeight: 1.5}}>{nft.metadata.description}</p>
                                                    </div>
                                                    <div className="meta-info">
                                                        <div className="author">
                                                            <div className="info">
                                                                <span>Owner</span>
                                                                <h6><a to="/authors-02">{nft.owner_id}</a> </h6>
                                                            </div>
                                                        </div>                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                            </div>
                            <div className="col-md-2"></div>
                        </div>

                      </div>
                  })
                }

                { 
                    tokens && tokens.length == 0 &&
                    <div className="themesflat-container">
                        <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                <div className="heading-live-auctions">
                                    <h2 className="tf-title" style={{padding:0}}>GemZ: NFT NYC 2022</h2>   
                                    <span style={{fontSize:16}}>{1111-qtySold} of 1111 available</span>                             
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                        <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-8">                            

                                <div className="swiper-container show-shadow carousel auctions">
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                            <div className="slider-item">
                                                <div className="sc-card-product">

                                                    { 
                                                        qtySold != 1111 &&
                                                        <div className="button-place-bid" style={{zIndex: 22}}>
                                                            <button onClick={() => setModalShow(true)} className="sc-button style-place-bid style bag fl-button pri-3" style={{width:'100%', marginBottom: 20, borderRadius: 15}}><span>Mint Free NFT</span></button>
                                                        </div>
                                                    }

                                                    <div className="card-media">
                                                        
                                                            <Swiper
                                                                spaceBetween={10}
                                                                effect={"fade"}
                                                                modules={[EffectFade, Autoplay]}              
                                                                loop
                                                                autoplay={{
                                                                    delay: 1,
                                                                    disableOnInteraction: false,
                                                                    pauseOnMouseEnter: false
                                                                }}
                                                                speed= {3000}
                                                                style={{borderRadius: '5px'}}
                                                                onSlideChangeTransitionStart={(e) => {
                                                                    switch(e.realIndex) {
                                                                        case 0: setSlideTitle('GemZ: NFT NYC 2022'); break;                                                                    
                                                                    }                                                                
                                                                }}
                                                            >
                                                                <SwiperSlide><img src={TMMPimg1} alt="GemZ: NFT NYC 2022" /></SwiperSlide>                                                            
                                                            </Swiper>
                                                            { 
                                                                qtySold != 1111 &&
                                                                <div className="button-place-bid" style={{zIndex: 22}}>
                                                                    <button onClick={() => setModalShow(true)} className="sc-button style-place-bid style bag fl-button pri-3"><span>Mint Free NFT</span></button>
                                                                </div>
                                                            }
                                                    </div>

                                                    { 
                                                        qtySold != 1111 &&
                                                        <div className="button-place-bid" style={{zIndex: 22}}>
                                                            <button onClick={() => setModalShow(true)} className="sc-button style-place-bid style bag fl-button pri-3" style={{width:'100%', marginBottom: 20, borderRadius: 15}}><span>Mint Free NFT</span></button>
                                                        </div>
                                                    }

                                                    <div className="card-title">
                                                        <h5><a>{slideTitle}</a></h5>
                                                        
                                                    </div>
                                                    <div className="meta-info">
                                                        <div className="author">
                                                            <div className="avatar" style={{borderRadius:0}}>
                                                                <img src={logo} alt="Axies" />
                                                            </div>
                                                            <div className="info">
                                                                <span>Creator</span>
                                                                <h6><a to="/authors-02">GemZ: Masters of Infinity</a> </h6>
                                                            </div>
                                                        </div>
                                                        <div className="price" style={{}}>                                                                        
                                                            <h5 style={{fontSize:40, display:'flex', alignItems: 'center', marginRight: 10}}>
                                                                { 
                                                                    qtySold == 1111 
                                                                    ? 'SOLD OUT'
                                                                    : <></>
                                                                }
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                    </div>       
                }         
            </section>
            <CardModalVIPLS
                show={modalShow}
                onHide={() => setModalShow(false)}
                onPurchase={handlePurchase}       
                mintError={mintError}    
                isMinting={isMinting}     
            />
        </Fragment>
    );
}


export default LiveAuctionVIPLS;
